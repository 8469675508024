/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { PercentSimpleItem } from '@/components/PercentISimpletem';
import React, { Children, ReactNode, useState } from 'react';

import { cn, twCn } from '@/utils';

import {
    DetectOverflowCallback,
    useDetectOverflow,
} from '@/hooks/useDetectOverflow';
import { Typography } from '@analytical-alley/ui';

const leftColumnClassName = 'w-1/3 min-w-56 2xl:min-w-26 md:w-1/4';
const rightColumnClassName = 'w-2/3 md:w-3/4';

const Row = ({
    title,
    value,
    percent,
    color,
    activeTitle,
    onSelect,
}: {
    onSelect: (title: string) => void;
    activeTitle: string | undefined;
    title: string;
    percent: number;
    value: ReactNode;
    color: string;
}) => {
    const isNegative = percent <= 0;
    const [overflow, setOverflow] = useState(false);
    const onTextOverflow: DetectOverflowCallback = (
        isOverflowing: boolean,
        { contentWidth, targetContentElement },
    ) => {
        if (isOverflowing) {
            targetContentElement.style.left = isNegative
                ? `calc(${percent}% + ${contentWidth}px)`
                : `calc(${percent}% - ${contentWidth}px)`;
        } else {
            targetContentElement.style.left = `${percent}%`;
        }
        setOverflow(isOverflowing);
    };

    const { containerRef, mainContentRef, targetContentRef } =
        useDetectOverflow(onTextOverflow, { throttleWait: 0 }, [percent]);

    const left = percent >= 0 ? '0%' : 'auto';
    const right = percent < 0 ? '100%' : 'auto';

    const barWidth = Math.abs(percent);

    return (
        <div
            className={cn(
                'flex flex-row h-12 align-middle w-full bg-gray-100 dark:bg-white dark:bg-opacity-10 rounded-lg',
                {
                    'bg-opacity-20 dark:bg-opacity-30': activeTitle === title,
                },
            )}
        >
            <span
                ref={isNegative ? containerRef : undefined}
                className={cn(
                    'flex self-center h-full pl-4 font-mono text-dark dark:text-white text-base leading-normal',
                    leftColumnClassName,
                )}
            >
                <span
                    onClick={() => {
                        onSelect(title);
                    }}
                    className="h-full cursor-pointer flex"
                >
                    <Typography
                        ref={isNegative ? mainContentRef : undefined}
                        variant="bodyM"
                        className="inline-block z-20 self-center text-wrap"
                    >
                        {title}
                    </Typography>
                </span>
            </span>
            <div
                ref={!isNegative ? containerRef : undefined}
                className={cn('relative', rightColumnClassName)}
            >
                <span
                    onClick={() => {
                        onSelect(title);
                    }}
                    ref={!isNegative ? mainContentRef : undefined}
                    className="absolute h-full cursor-pointer"
                    style={{
                        opacity:
                            !activeTitle || activeTitle === title ? 0.9 : 0.1,
                        backgroundColor: color,
                        width: `${Math.max(barWidth, 0.5)}%`,
                        left,
                        right,
                    }}
                />
                <span
                    ref={targetContentRef}
                    onClick={() => {
                        onSelect(title);
                    }}
                    className="flex z-20 h-full absolute text-white text-nowrap px-4 cursor-pointer"
                >
                    <div className="flex gap-3 font-mono z-20 relative">
                        <Typography
                            variant="bodyS"
                            className={cn(
                                'self-center text-dark dark:text-white',
                                {
                                    'text-white': overflow,
                                },
                            )}
                        >
                            {value}
                        </Typography>
                        <PercentSimpleItem percent={percent} />
                    </div>
                </span>
            </div>
        </div>
    );
};

const ScaleLines = () => {
    const percentages = [0, 25, 50, 75, 100];

    return (
        <>
            {percentages.map((percentage) => {
                return (
                    <div
                        key={percentage}
                        className="z-10"
                        style={{
                            position: 'absolute',
                            height: '100%',
                            left: `${percentage}%`,
                        }}
                    >
                        <div
                            className={twCn(
                                'w-0.5 border-l -translate-x-1/2 border-dark border-opacity-50 dark:border-blue-200 dark:border-opacity-50 border-dotted',
                                {
                                    'w-[5px] bg-dark bg-opacity-50 dark:bg-blue-200 dark:bg-opacity-50 rounded-sm border-none':
                                        !percentage,
                                },
                            )}
                            style={{
                                position: 'absolute',
                                height: '100%',
                            }}
                        />
                        <div
                            className="text-dark dark:text-white font-mono"
                            style={{
                                position: 'absolute',
                                bottom: '-1.5rem',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            {percentage}%
                        </div>
                    </div>
                );
            })}
        </>
    );
};
const HorizontalBarGraphBase = ({ children }: { children: ReactNode }) => {
    const hasChildren = Children.count(children) > 0;

    return (
        <div>
            {hasChildren ? (
                <div className="relative flex flex-col text-xs text-white whitespace-nowrap w-full gap-3 mb-[1.5rem]">
                    <div className="absolute flex h-full flex-row align-middle w-full">
                        <span className={cn(leftColumnClassName)}></span>
                        <div className={cn('relative', rightColumnClassName)}>
                            <ScaleLines />
                        </div>
                    </div>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export const HorizontalBarGraph = Object.assign(HorizontalBarGraphBase, {
    Row,
});
