import { Typography } from '@analytical-alley/ui';
import React from 'react';

export const PercentSimpleItem = ({ percent }: { percent: number }) => {
    if (percent > 0) {
        return (
            <div className="z-40 flex items-center text-xs font-semibold text-green-400 dark:text-green-400 text-center">
                <Typography
                    className="text-green-400 dark:text-green-400"
                    variant="bodyXS"
                >
                    {percent}%
                </Typography>
            </div>
        );
    }

    if (percent < 0) {
        return (
            <div className="flex items-center font-semibold text-center text-error dark:text-error">
                <Typography
                    className="text-error dark:text-error"
                    variant="bodyXS"
                >
                    {percent}%
                </Typography>
            </div>
        );
    }

    return (
        <div className="ml-5 flex w-0 flex-1 items-center justify-end font-bold">
            <Typography
                className="text-cyan-300 dark:text-cyan-300"
                variant="bodyXS"
            >
                0%
            </Typography>
        </div>
    );
};
